<template>
  <div class="mapView" :style="{paddingTop: paddingTop + 'px' }">
    <baidu-map class="bm-view" ak="PywenqGYcgkx1PzsfNXiEhpZpR4LIWUX" :center="center" :zoom="18">
      <bm-marker
          :position="center"
          :zIndex="999999999"
      >
      </bm-marker>
    </baidu-map>

    <div class="content">
      <div class="addressInfo">
<!--        <div class="name">苗阳服装有限公司</div>-->
        <div class="address"><img src="./../../assets/images/map/location.png"/>
          <span>{{ afterSaleSet.addres }}</span></div>
      </div>

      <div class="menu">
        <div class="menu_item">
          <div class="title">
            <img src="./../../assets/images/map/phone.png" :style="{height: px2rem(11), width: px2rem(11)}"/>
            <span>客服热线：</span>
          </div>
          <a :href="'tel:'+afterSaleSet.phone" class="value phone">{{afterSaleSet.phone}}</a>
        </div>
        <div class="menu_item">
          <div class="title">
            <img src="./../../assets/images/map/wechat.png" :style="{height: px2rem(9), width: px2rem(11)}"/>
            <span>微信客服：</span>
          </div>
          <a class="value">
            <img :src="afterSaleSet.kfWechat" width="200px">
          </a>
        </div>
        <div class="menu_item">
          <div class="title">
            <img src="./../../assets/images/map/time.png" :style="{height: px2rem(11), width: px2rem(11)}"/>
            <span>在线时间</span>
          </div>
          <div class="value onlineTime">{{afterSaleSet.onLineTime}}
          </div>
        </div>
        <div class="menu_item">
          <div class="title">
            <img src="./../../assets/images/map/must.png" :style="{height: px2rem(12), width: px2rem(13)}"/>
            <span>退货必看</span>
          </div>
          <div class="value mustSee"> {{afterSaleSet.notice}}
          </div>
        </div>
        <div class="menu_item">
          <div class="title">
            <img src="./../../assets/images/map/th.png" :style="{height: px2rem(12), width: px2rem(13)}"/>
            <span>退换货地址</span>
          </div>
          <div class="value address">
            <p>收件人：{{ afterSaleSet.recipients }}</p>
            <p>电话：{{ afterSaleSet.phone }}</p>
            <p>地址：{{ afterSaleSet.exchangeAddress }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from "vue-baidu-map/components/overlays/Marker"; //点标注
import {mapGetters} from 'vuex'
export default {
  name: "ShouHou",
  components: {
    BaiduMap,
    BmMarker,
  },
  data() {
    return {
      //https://lbsyun.baidu.com/jsdemo.htm#wAddressParseSingle 去这里拿
      center: {lng: 113.0156982285722, lat: 28.10678861112164},
      paddingTop: 0,
      afterSaleSet:{},
      titleContent:'',
    }
  },
  methods: {
    ...mapGetters(["getAfterSaleSet"]),
    getLocationByAddress(address) {
      const regex = /([\u4e00-\u9fa5]+省)/; // 匹配汉字加上"省"的模式
      const result = address.match(regex);
      //创建地址解析器实例
      var myGeo = new window.BMapGL.Geocoder();
      myGeo.getPoint('湖南省长沙市雨花区时代阳光大道裕天国际商汇中心', (point) => {
        if (point) {
          this.center = {lng: point.lng, lat: point.lat}
        } else {
          console.log("无法定位");
        }
      },result[1]);
    }
  },
  mounted() {
    this.afterSaleSet = this.getAfterSaleSet();
    this.titleContent = this.afterSaleSet.addres;
    this.getLocationByAddress(this.afterSaleSet.addres);
    let rem = 1.973333;
    let px = rem * document.documentElement.getAttribute('data-size');
    let windowHeight = document.documentElement.clientHeight;
    this.paddingTop = (windowHeight - px * 4)
  }
}
</script>

<style scoped lang="less">
.bm-view {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mapView {
  min-height: 100%;
}

.content {
  background: #FFFFFF;
  box-shadow: 3px 1px 14px 0px rgba(134, 134, 134, 0.2900);
  border-radius: 13px 13px 0px 0px;
  position: relative;
  z-index: 2;

  .addressInfo {
    height: 74px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #eee;

    .name {
      font-size: 15px;
      color: #121212;
      line-height: 1;
    }

    .address {
      color: #8e8e8e;
      font-size: 10px;
      margin-top: 10px;
      padding-left: 10px;

      span {
        vertical-align: middle;
        line-height: 12px;
      }

      img {
        width: 9px;
        height: 12px;
        margin-right: 3px;
        vertical-align: middle;
      }
    }
  }

  .menu {
    padding: 0 15px;

    .menu_item {
      border-bottom: 1px solid #eee;
      min-height: 75px;
      padding: 17px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        display: flex;
        align-items: center;

        img {
          margin-right: 8px;
        }

        span {
          font-size: 12px;
          font-weight: 500;
          color: #414141;
        }
      }

      .value {
        padding-left: 20px;
        line-height: 1;

        &.phone {
          font-size: 12px;
          color: #14B785;
          margin-top: 8px;
        }

        &.onlineTime {
          margin-top: 3px;
          font-size: 10px;
          color: #8E8E8E;
          line-height: 14px;
        }

        &.mustSee {
          margin-top: 3px;
          font-size: 10px;
          color: #333333;
          line-height: 15px;
        }

        &.address {
          margin-top: 3px;

          p {
            font-size: 10px;
            color: #8E8E8E;
            line-height: 17px;
          }
        }
      }
    }
  }
}
</style>
